import React from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import img1 from "../assets/img8.jpg"
import img2 from "../assets/img2.jpg"
import img3 from "../assets/img3.jpg"
import img4 from "../assets/img7.jpg"
import img5 from "../assets/img5.jpg"
import img6 from "../assets/img6.jpg"
import img7 from "../assets/img9.jpg"
import img10 from "../assets/img10.jpg"

export const data = [
    {
      id: 1,
      img: img1,
    },
    {
      id: 2,
      img: img2,
    },
    {
      id: 3,
      img: img3,
    },
    {
      id: 4,
      img: img4,
    },
    {
      id: 5,
      img: img5,
    },
    {
      id: 6,
      img: img10,
    },
    {
      id: 7,
      img: img7,
    },
    {
      id: 8,
      img: img6,
    },
  ];


const Slider = () => {
    const slideLeft = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft - 260;
      };
    
      const slideRight = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft + 260;
      };
  return (
    <>
      <section id='pictures'>
      <div className='relative flex items-center bg-primary'>
        <MdChevronLeft className='text-white opacity-50 cursor-pointer hover:opacity-100' onClick={slideLeft} size={40} />
        <div
          id='slider'
          className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide '
        >
          {data.map((item) => (
            <img
              className='w-[260px] sm:w-[350px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300'
              src={item.img}
              alt='/'
            />
          ))}
        </div>
        <MdChevronRight className='text-white opacity-50 cursor-pointer hover:opacity-100' onClick={slideRight} size={40} />
      </div>
      </section>
    </>
  )
}

export default Slider
//
//