import React from 'react';
import Vector from "../assets/img1.jpg"

const Menu = () => {
    const bgImage = {
        backgroundImage: `url(${Vector})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
      };

  return (
    <section id='menu'>
    <div className=" w-[100%] text-white m-0 md:my-auto py-8 md:mx-0 lg:mx-0 "  style={bgImage}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8  font-mono">
        <div className="bg-primary bg-opacity-70 shadow-md rounded-md p-4 md:ml-6 sm:m-0">
          <h2 className="text-lg  font-bold mb-2 flex justify-center">FÖRRÄTTER</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">VITLÖKSBRÖD</span> - 39 kr
              <p className="text-sm text-orange-200">Rostat bröd, tzatziki.</p>
            </li>
            <li>
              <span className="font-semibold">BRUSCHETTA</span> - 59 kr
              <p className="text-sm text-orange-200">Rostat bröd med lök, olivolja och tomat, basilika.</p>
            </li>
            <li>
              <span className="font-semibold">OLIVER/MOZZARELLA</span> - 69 kr
              <p className="text-sm text-orange-200">Marinerade oliver, mini-mozzarella i örtolja.</p>
            </li>
            <li>
              <span className="font-semibold">SKAGEN</span> - 89 kr
              <p className="text-sm text-orange-200">Husets skagenröra på rostat bröd.</p>
            </li>
          </ul>
          <br/>
          <h2 className="text-lg font-bold mb-2 hidden md:flex justify-center ">DESSERT</h2>
          <ul className="hidden md:inline list-inside">
            <li>
              <span className="font-semibold">PANNA COTTA</span> - 69 kr
              <p className="text-sm text-orange-200">Björnbärskompott.</p>
            </li>
            <li>
              <span className="font-semibold">SORBET X2</span> - 69 kr
              <p className="text-sm text-orange-200">Citronsorbet, äppelsorbet, halloncoulis.</p>
            </li>
            <li>
              <span className="font-semibold">CHOKLADPRALIN X3</span> - 59 kr
              <p className="text-sm text-orange-200">3 sorters chokladpralin serveras med kaffe.</p>
            </li>
          </ul>
        </div>
        <div className="bg-primary bg-opacity-70 shadow-md rounded-md p-4 md:mr-6">
          <h2 className="text-lg font-bold mb-2 flex justify-center">PASTA</h2>
          <ul className="list-inside">
            <li>
              <span className="font-semibold">TOMATEN</span> - 98 kr
              <p className="text-sm text-orange-200">Mustiga tomatsås med mozzarella, basilika,
pumpakärnor, solrosfrön, Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">MISS PIGGY</span> - 109 kr
              <p className="text-sm text-orange-200">Carbonara på vårt vis med nymalen svartpeppar,
äggula, Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">CHICKY CHICKY</span> - 109 kr
              <p className="text-sm text-orange-200">Krämig tomatsås med kyckling, vitlök och basilika,
Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">MAMMA MU</span> - 109 kr
              <p className="text-sm text-orange-200">Mustig nöt-köttfärssås med grädde och härliga kryddor,
Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">CHICKY MAMA</span> - 109 kr
              <p className="text-sm text-orange-200">Krämig sås med ost, kyckling, chili, vitlök och basilika,
Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">ZWAMPEN</span> - 109 kr
              <p className="text-sm text-orange-200">Champinjonsås med kycklingfilé, vitlök, pinjenötter,
Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">FERDINAND</span> - 149 kr
              <p className="text-sm text-orange-200">Kryddig pepparsås med marinerad strimlad oxfilé,
vitlök, Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">GAMBERETTI</span> - 159 kr
              <p className="text-sm text-orange-200">Marinerade Vannamei räkor, krämig tomatsås med 
vitlök och basilika, toppad med handskalade räkor ovh riven vitlök, Parmigiano-Reggiano.</p>
            </li>
          </ul>
          Extra stor portion 25:-, Barn -15:-
Glutenfritt alternativ finns
        </div>
        <div className="bg-primary bg-opacity-70 shadow-md rounded-md p-4 md:mr-6  md:hidden lg:inline">
          <h2 className="text-lg font-bold flex justify-center">PIADIZZA</h2>
          <p className="mx-2 text-sm text-orange-200">(Deg på durummjöl, tunn och krispig och smakrik botten)</p>
          <p className='mx-2 mb-2 text-sm text-orange-200'>I Alla piadizzor ingår husets ostblandning och tomatsås förutom Bianco.</p>
          <ul className="list-inside mb-2">
            <li>
              <span className="font-semibold">PINEAPPLE EXPRESS</span> - 125 kr
              <p className="text-sm text-orange-200">Skinka, ananas.</p>
            </li>
            <li>
              <span className="font-semibold">LÅT ZALAMIN KOMMA IN</span> - 145 kr
              <p className="text-sm text-orange-200">Salami, nötfärs, lök, basilika/lime sås</p>
            </li>
            <li>
              <span className="font-semibold">MAFIA BROTHERS</span> - 145 kr
              <p className="text-sm text-orange-200">Lök, nötfärs, bacon.</p>
            </li>
            <li>
              <span className="font-semibold">THE GOAT</span> - 145 kr
              <p className="text-sm text-orange-200">Chévre, lök, champinjoner, pinjenötter, tomat, ruccola, honung.</p>
            </li>
            <li>
              <span className="font-semibold">SAGAN OM RÄKAN</span> - 145 kr
              <p className="text-sm text-orange-200">Skinka, champinjoner, pinjenötter, handskalade räkor.</p>
            </li>
            <li>
              <span className="font-semibold">SHABBA CURRY CASH</span> - 145 kr
              <p className="text-sm text-orange-200">Skinka, bacon, ananas, curry.</p>
            </li>
            <li>
              <span className="font-semibold">JALLA!JALLA!</span> - 145 kr
              <p className="text-sm text-orange-200">Skinka, champinjoner, nötfärs, tzatziki, Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">SAGAN OM RÄKANS ÅTERKOMST</span> - 150 kr
              <p className="text-sm text-orange-200">Handskalade räkor, nötfärs, lök, ananas, curry.</p>
            </li>
            <li>
              <span className="font-semibold">THE BIG LEBULLSKI</span> - 165 kr
              <p className="text-sm text-orange-200">Lök, oxfilé, champinjoner, basilika/lime-sås.</p>
            </li>
            <li>
              <span className="font-semibold">GUDFADERN</span> - 165 kr
              <p className="text-sm text-orange-200">Champinjoner, lufttorkad skinka, tomat, ruccola, pinjenötter.</p>
            </li>
            <li>
              <span className="font-semibold">EMIL I BIANCOBERGA</span> - 175 kr
              <p className="text-sm text-orange-200">Olivolja, ost, citron, skagenröra, ruccola, stenbitsrom.</p>
            </li>
          </ul>
          Basilika/lime-sås • Tzatziki   15:-
        </div>
        <div className="bg-primary bg-opacity-70 shadow-md rounded-md p-4 md:mr-6 hidden md:inline ml-6 lg:hidden">
          <h2 className="text-lg font-bold flex justify-center">PIADIZZA</h2>
          <p className="mx-2 mb-2 text-sm text-orange-200">(Deg på durummjöl, tunn och krispig och smakrik botten)</p>
          
          <div>
          <ul className="list-inside mb-2">
            <li>
              <span className="font-semibold">PINEAPPLE EXPRESS</span> - 125 kr
              <p className="text-sm text-orange-200">Skinka, ananas.</p>
            </li>
            <li>
              <span className="font-semibold">LÅT ZALAMIN KOMMA IN</span> - 145 kr
              <p className="text-sm text-orange-200">Salami, nötfärs, lök, basilika/lime sås</p>
            </li>
            <li>
              <span className="font-semibold">MAFIA BROTHERS</span> - 145 kr
              <p className="text-sm text-orange-200">Lök, nötfärs, bacon.</p>
            </li>
            <li>
              <span className="font-semibold">THE GOAT</span> - 145 kr
              <p className="text-sm text-orange-200">Chévre, lök, champinjoner, pinjenötter, tomat, ruccola, honung.</p>
            </li>
            <li>
              <span className="font-semibold">SAGAN OM RÄKAN</span> - 145 kr
              <p className="text-sm text-orange-200">Skinka, champinjoner, pinjenötter, handskalade räkor.</p>
            </li>
            <li>
              <span className="font-semibold">SHABBA CURRY CASH</span> - 145 kr
              <p className="text-sm text-orange-200">Skinka, bacon, ananas, curry.</p>
            </li>
          </ul>
          </div>
          
        </div>
        <div className="bg-primary bg-opacity-70 shadow-md rounded-md p-4 md:mr-6 hidden  md:flex flex-col h-full lg:hidden">
        <h2 className="text-lg font-bold flex justify-center">PIADIZZA</h2>
        <p className='mx-2 mb-2 text-sm text-orange-200'>I Alla piadizzor ingår husets ostblandning och tomatsås förutom Bianco.</p>
        <ul>
            <li>
              <span className="font-semibold">JALLA!JALLA!</span> - 145 kr
              <p className="text-sm text-orange-200">Skinka, champinjoner, nötfärs, tzatziki, Parmigiano-Reggiano.</p>
            </li>
            <li>
              <span className="font-semibold">SAGAN OM RÄKANS ÅTERKOMST</span> - 150 kr
              <p className="text-sm text-orange-200">Handskalade räkor, nötfärs, lök, ananas, curry.</p>
            </li>
            <li>
              <span className="font-semibold">THE BIG LEBULLSKI</span> - 165 kr
              <p className="text-sm text-orange-200">Lök, oxfilé, champinjoner, basilika/lime-sås.</p>
            </li>
            <li>
              <span className="font-semibold">GUDFADERN</span> - 165 kr
              <p className="text-sm text-orange-200">Champinjoner, lufttorkad skinka, tomat, ruccola, pinjenötter.</p>
            </li>
            <li>
              <span className="font-semibold">EMIL I BIANCOBERGA</span> - 175 kr
              <p className="text-sm text-orange-200">Olivolja, ost, citron, skagenröra, ruccola, stenbitsrom.</p>
            </li>
          </ul>
          <p className='mt-auto mb-0'>Basilika/lime-sås • Tzatziki   15:-</p>
        </div>
        <div className="md:hidden bg-primary bg-opacity-70 shadow-md rounded-md p-4 md:mr-6">
          <h2 className="text-lg font-bold mb-2 flex md:hidden justify-center ">DESSERT</h2>
            <ul className="inline md:hidden list-inside">
              <li>
                <span className="font-semibold">PANNA COTTA</span> - 69 kr
                <p className="text-sm text-orange-200">Björnbärskompott.</p>
              </li>
              <li>
                <span className="font-semibold">SORBET X2</span> - 69 kr
                <p className="text-sm text-orange-200">Citronsorbet, äppelsorbet, halloncoulis.</p>
              </li>
              <li>
                <span className="font-semibold">CHOKLADPRALIN X3</span> - 59 kr
                <p className="text-sm text-orange-200">3 sorters chokladpralin serveras med kaffe.</p>
              </li>
            </ul>
        </div>
        
      </div>
      <br/>
      <br/>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8  font-mono">
        <div className="bg-primary bg-opacity-70 shadow-md rounded-md p-4 md:ml-6 sm:m-0">
          <h2 className="text-lg  font-bold mb-0 flex justify-center">MOUSSERANDE</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Prosecco Piccoloflaska [20cl]</span> - 95 kr
            </li>
          </ul>
          <h2 className="text-lg font-bold mb-0 flex justify-center ">VITT VIN</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Husets glas/flaska</span> - 85/298 kr
            </li>
            <li>
              <span className="font-semibold">Sartori Mont’Albano Pinot Grigio delle Venezie DOC</span> - 95/380 kr
              <p className="text-sm text-orange-200">Casa Vinicola Sartori , Venetien, Italien. Torrt vin med toner
av grönt äpple, aprikos och citrusfrukt. Friskt och
balanserat avslut. Glas/flaska</p>
            </li>
          </ul>
          <h2 className="text-lg  font-bold mb-0 flex justify-center">ROSE VIN</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Husets glas/flaska</span> - 85/298 kr
            </li>
          </ul>
          <h2 className="text-lg font-bold mb-0 flex justify-center ">RÖTT VIN</h2>
          <ul className="inline list-inside ">
            <li>
              <span className="font-semibold">Husets glas/flaska</span> - 85/298 kr
            </li>
            <li>
              <span className="font-semibold">Sartori Mont’Albano Nero d’Avola Sicilia DOC</span> - 95/380 kr
              <p className="text-sm text-orange-200">Casa Vinicola Sartori, Sicilien, Italien. Medelfylligt vin bärigt
vin med toner av björnbär, hallon, viol och kryddor.
Rund kryddig avslutning. Glas/flaska</p>
            </li>
          </ul>
          <h2 className="text-lg  font-bold mb-0 flex justify-center">ÖL</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Fatöl Perroni [40cl]</span> - 79 kr
            </li>
            <li>
              <span className="font-semibold">Öl flaska </span> - fråga personalen för dagens utbud
            </li>
            <li>
              <span className="font-semibold">Alkoholfri öl [33cl]</span> - 49 kr
            </li>
          </ul>
          <h2 className="text-lg  font-bold mb-0 flex justify-center">CIDER</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Jordgobb/Lime [40cl]</span> - 72 kr
            </li>
            <li>
              <span className="font-semibold">Alkoholfri cider</span> - 49 kr
            </li>
          </ul>
          <h2 className="text-lg  font-bold mb-0 flex justify-center">SPRIT</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Limoncello [4cl, 6cl]</span> - 104/156 kr
              <p className="text-sm text-orange-200">Fråga om dagens utbud</p>
            </li>
          </ul>
          <h2 className="text-lg  font-bold mb-0 flex justify-center">ÖVRIG DRYCK</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Pepsi, Pepsi MAX, 7-UP, Zingo [33cl]</span> - 30 kr
            </li>
            <li>
              <span className="font-semibold">San Pellegrino</span>, mineralvatten [33cl] - 30 kr
            </li>
          </ul>
        </div>
        <div className="bg-primary bg-opacity-70 shadow-md rounded-md p-4 md:mr-6">
        <h2 className="text-lg  font-bold mb-0 flex justify-center">KAFFEDRINKAR</h2>
        <p className="text-sm text-orange-200 flex justify-center">4cl 125 / 6cl 165</p>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Irish Coffee</span>
            </li>
            <li><span className="font-semibold">Baileys Coffee</span></li>
            <li><span className="font-semibold">Kaffe Karlsson</span> </li>
            <li><span className="font-semibold">Espresso Martini</span> </li>
          </ul>
          <h2 className="text-lg  font-bold mb-0 flex justify-center">DRINKAR</h2>
          <p className="text-sm text-orange-200 flex justify-center">4cl 125 / 6cl 165</p>
          <ul className="list-inside ">
          <li>
              <span className="font-semibold">Gin&Tonic</span> 
            </li>
            <li>
              <span className="font-semibold">Frozen Irish coffee</span> 
              <p className="text-sm text-orange-200">Baileys, Kahula, Whiskey, Espresso, vaniljglass, grädde</p>
            </li>
            <li>
              <span className="font-semibold">Limoncello spritz</span>
              <p className="text-sm text-orange-200">Prosecco, Limoncello, citronjuice, sodavatten</p>
            </li>
            <li>
              <span className="font-semibold">Aperol spritz</span>
              <p className="text-sm text-orange-200">Prosecco, Aperol, sodavatten</p>
            </li>
            <li>
              <span className="font-semibold">Grappa sours</span>
              <p className="text-sm text-orange-200">Grappa, citronjuice, äggvita, Angostura bitters</p>
            </li>
            <li>
              <span className="font-semibold">Mango Daquiri</span>
              <p className="text-sm text-orange-200">Rom, mangopuré, limejuice, sockerlag</p>
            </li>
            <li>
              <span className="font-semibold">Gin Mango</span>
              <p className="text-sm text-orange-200">Gin, mangopuré, limejuice, 7-up</p>
            </li>
            <li>
              <span className="font-semibold">Mango tango</span>
              <p className="text-sm text-orange-200">Vodka, mangopuré, limejuice, Grenadin, Zingo</p>
            </li>
            <li>
              <span className="font-semibold">Hallon Daquiri</span>
              <p className="text-sm text-orange-200">Jägermeister, hallonpuré, 7-up</p>
            </li>
            <li>
              <span className="font-semibold">Hallonlakrits</span>
              <p className="text-sm text-orange-200">Jägermeister, hallonpuré, 7-up, citronjuice</p>
            </li>
            <li>
              <span className="font-semibold">Äpple Daquiri</span>
              <p className="text-sm text-orange-200">Rom, äppelpuré,  limejuice, sockerlag</p>
            </li>
            <li>
              <span className="font-semibold">Äpple sour</span>
              <p className="text-sm text-orange-200">Vodka, citronjuice, äppelpuré, 7-up</p>
            </li>
          </ul>
          
          <h2 className="text-lg  font-bold mb-0 flex justify-center">KAFFE</h2>
          <ul className="list-inside ">
            <li>
              <span className="font-semibold">Kaffe/Te</span> - 32 kr
            </li>
            <li>
              <span className="font-semibold">Espresso/Capuccino/Kaffe latte/Caffe macchiato/Chokladmjölk</span> - 42 kr
            </li>
          </ul>
        </div>
        
        
      </div>
    </div>
    </section>
  );
};

export default Menu;
