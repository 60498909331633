import React from 'react'
import {FaEnvelope} from "react-icons/fa"
import LogoLight from "../assets/logoLight1.png"
import { HashLink as Link } from 'react-router-hash-link'

const Navbar = () => {


  return (
    <>
      <div className='shadow-md bg-primary h-[50px] text-white duration-200'>
        <div className="container py-0  sm:py-0">
          <div className='flex justify-between items-center py-0'>
            <div>
              {/*//TODO link to needed pages */ }
              <a href="#main" className='flex items-center gap-2 text-2xl sm:text-3xl font-bold'>
                  <img src={LogoLight} alt="Tittelino Logo"
                  className='w-24 sm:w-28 ' /> 
              </a>
            </div>
            <div 
              className="flex justify-between 
              items-center gap-4">
              {/** <div>
                
              </div>*/}
              <ul className='flex items-center gap-4'>
                {/*//TODO valid links for navigation*/ }
                <li>
                    <Link className='inline-block 
                   pt-4 px-4 pb-4
                   hover:text-secondary' to="#menu">Meny</Link>
                </li>
                
                <li>
                   <Link className='inline-block 
                   pt-4 px-4 pb-4
                   hover:text-secondary' to="#pictures">Bilder</Link>
                </li>
              </ul>
              <button
              className='bg-secondary  hidden sm:flex
              text-white px-4 py-1 
              rounded-full hover:scale-105 duration-300 
              items-center gap-3'
              ><a href={`mailto:info@tittelino.se`}>Contacta oss</a> 
                <FaEnvelope 
                className='text-xl
                text-white drop-shadow-sm
                cursor-pointer'/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar;
