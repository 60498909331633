import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Footer from './components/Footer'
import Menu from './components/Menu'
import { BrowserRouter } from 'react-router-dom'
import Slider from './components/Slider'


const App = () => {
  return (
    <BrowserRouter>
    <div >
      <Navbar/>
      <Hero/>
      <Menu />
      <Slider />
      <Footer />
    </div>
    </BrowserRouter>
  )
  
}

export default App
