import React from 'react'
import Vector from "../assets/bg.jpg"
import food1 from "../assets/pasta8.png"


const Hero = () => {
  const bgImage = {
    backgroundImage: `url(${Vector})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
  };

  return (
    <>
    <section id='main'>
    <div
        className="min-h-[350px] sm:min-h-[500px] 
         flex justify-center items-center 
         dark:text-white duration-200"
        style={bgImage}
      >
        <div className="container pb-8 sm:pb-0 min-h-[600px]">
          <div className="grid grid-cols-1 sm:grid-cols-2 mt-6 sm:mt-0">
            {/* text content section */}
            <div
              data-aos="zoom-out"
              data-aos-duration="400"
              data-aos-once="true"
              className="flex flex-col justify-center 
              gap-4 pt-4 sm:pt-0 text-center 
              sm:text-left order-2 sm:order-1"
            >
              <h1 className="text-5xl sm:text-6xl 
              lg:text-7xl font-bold ">
                Välkommen till{" "}
                <span class="bg-clip-text text-transparent 
                bg-secondary">
                  Tittelino
                </span>{" "}
                Pasteria
              </h1>
              {/*
              <p className="text-sm ">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque
                reiciendis inventore iste ratione ex alias quis magni at optio
              </p>
              <div>
                <button className="bg-secondary hover:scale-105 duration-200 
                 text-white py-2 px-4 rounded-full">
                  Boka bord
                </button>
              </div>*/}
            </div>
            {/* Image section */}
            <div className="min-h-[260px] sm:min-h-[600px] 
            flex justify-center items-center 
            relative order-1 sm:order-2 ">
              <div className="h-[300px] sm:h-[500px] 
              overflow-hidden flex justify-center 
              items-center ">
                <img
                  data-aos="zoom-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                  src={food1}
                  alt="img"
                  className="w-[300px] sm:w-[500px] 
                  sm:scale-125  mx-auto spin "
                />
              </div>
              {/** 
              <div className="flex lg:flex-col 
              lg:top-1/2 lg:-translate-y-1/2 lg:py-2 
              justify-center gap-4 absolute bottom-[0px] 
              lg:-right-10 bg-white/30 rounded-full">
                {ImageList.map((item) => (
                  <img
                    data-aos="zoom-in"
                    data-aos-duration="400"
                    data-aos-once="true"
                    src={item.image}
                    onClick={() => {
                      setImageId(
                        item.id === 1
                          ? food1
                          : item.id === 2
                          ? food2
                          : food3
                      );
                    }}
                    alt="biryani img"
                    className="max-w-[80px] h-[80px] object-contain inline-block hover:scale-105 duration-200"
                  />
                ))}
              </div>
              */}
            </div>
          </div>
          
        </div>
      </div>
      </section>
    </>
  )
}

export default Hero
