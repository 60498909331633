import React from "react";
//FaInstagram,
import {
  FaFacebook,
  
  FaLocationArrow,
  FaMobileAlt,
  FaEnvelope,
} from "react-icons/fa";
import footerLogo from "../assets/logoLight1.png";

const Footer = () => {
  return (
    <div className="bg-primary text-white">
      <section className="max-w-[1200px] mx-auto" id="footer">
        <div className=" grid md:grid-cols-3 py-2">
          <div className=" py-4 px-4 ">
            <h1 className="sm:text-3xl text-xl font-bold sm:text-left text-justify mb-3 flex items-center gap-3">
              <img src={footerLogo} alt="Logo" className="max-w-[150px]" />
            </h1>
            <p className="">
            Nu har vi öppnat portarna för tittelino pasteria! Vi är en liten mysig kvarters krog med goda pasta rätter för alla. Rätter ifrån 79-139kr som går att äta lika bra på plats som att ta med sig. Vi längtar efter att ge er god service, mat och dryck. vi ses!{" "}
            </p>
            <br />
            <div className="flex items-center gap-3">
              <FaLocationArrow />
              <p>Storgatan 33, Växjö, Sweden</p>
            </div>
            <div className="flex items-center gap-3 mt-3">
              <FaMobileAlt />
              <a href={`tel:"0470-55 66 66"`}>0470-55 66 66</a>
            </div>
            {/* Social Handle */}
            <div className="flex items-center gap-3 mt-6">
              {/*
              <a href="#">
                <FaInstagram className="text-3xl" />
              </a>*/}
              <a href="https://www.facebook.com/Tittelinopasteria">
                <FaFacebook className="text-3xl" />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 col-span-2 md:pl-10 ">
            <div className="">
              <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                Möjligheter
                </h1>
                <ul className={`flex flex-col gap-3`}>
                  <li className="cursor-pointer">Bordsbokning
                  <p>- Ring eller maila oss </p>
                <div className="flex flex-row items-center gap-3">
                  <FaEnvelope/> <a href={`mailto:info@tittelino.se`}>info@tittelino.se</a> 
                </div></li>
                  <li className="cursor-pointer">Take away</li>
                </ul>               
              </div>
            </div>
            <div className="">
              <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                Öppettider
                </h1>
                <ul className={`flex flex-col gap-3`}>
                  <li className="cursor-pointer">Måndag:               stängt</li>
                  <li className="cursor-pointer">Tisdag - Fredag:      15.00-</li>
                  <li className="cursor-pointer">Lördag:               12.00- 21.00</li>
                  <li className="cursor-pointer">Söndag:               stängt</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-center py-10 border-t-2 border-gray-300/50">
            @copyright 2024 Designed by Oleksandra Kryshtapiuk
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;